<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <md-content class="md-scrollbar">
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="companyName" @md-selected="getCompanySelected" :md-options="companies" @md-changed="getCompanies"  @md-opened="getCompanies" :class="{'md-invalid': submitted && $v.entity.companyId.$error }">
                                    <label>Đơn vị vận chuyển</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.companyId.isSelected">Vui lòng chọn đơn vị vận chuyển</span>
                                </md-autocomplete>
                                <md-button @click="openCompany()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                            <md-field :class="{'md-invalid': submitted && $v.entity.licensePlate.$error }">
                               <label for="licensePlate">Biển số</label>
                               <md-input name="licensePlate" v-model="entity.licensePlate"></md-input>
                               <span class="md-error" v-if="submitted && !$v.entity.licensePlate.required">Vui lòng nhập biển số</span>
                            </md-field>
                            <md-field>
                               <label for="branch">Nhãn hiệu</label>
                               <md-input name="branch" v-model="entity.branch"></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
            </md-content>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
            <companyList ref="companyList" title="công ty" :type="companyType" @close="closeCompany"/>
        </md-content>
    </md-dialog>
</template>
<script>
    import vehicleOutsideService from '../../../api/vehicleOutsideService';
    import companyService from '../../../api/companyService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import companyList from '../../../components/popup/_CompanyList.vue';

    export default ({
        components: {
            companyList
        },
        data() {
            return {
                showDialog: false,
                title: '',
                id: 0,
                submitted: false,
                companyType: common.companyType.transport,
                entity: { id: 0, companyId: 0, licensePlate: '', branch: '' },
                saveAndCreate: false,
                companyName: '',
                companies: [],
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            async open(id){
                this.id = id;
                if(id == 0){
                    this.title = 'Thêm mới xe ngoài';
                    this.companyName = "";
                    this.entity = { id: 0, companyId: 0, licensePlate: '', branch: '' };
                }
                else {
                    this.saveAndCreate = false;
                    this.title = 'Cập nhật xe ngoài';
                    this.getById();
                }
                this.submitted = false;
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                this.entity.orderType = 2;
                vehicleOutsideService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$emit('close');
                        if(!this.saveAndCreate){
                            this.showDialog = false;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                vehicleOutsideService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$emit('close');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                vehicleOutsideService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.companyName = response.data.company.companyAlias;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            closeCompany(item){
                this.companyName = item.companyName;
                this.entity.companyId = item.id;
                this.$refs.companyList.close();
            },

            getCompanySelected(val){
                this.entity.companyId = val.id;
                this.companyName = val.companyName;
            },

            openCompany(){
                this.$refs.companyList.open(this.companyType);
            },

            getCompanies(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                companyService.getTransport(search).then((response) => {
                    if(response.statusCode == 200){
                        this.companies = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: { 
            companyName: function (val) { 
                if(val == ''){
                    this.entity.companyId = 0;
                }
            },
        },
        validations: {
            entity: {
                companyId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                licensePlate: { required }
            }
       }
     })

</script>

<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 600px;
        height: 360px;
        max-width: 600px;
    }
    .md-scrollbar {
        height: 350px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>